import React from "react";
import Layout from "../components/layout";
import "react-tabs/style/react-tabs.css";

const IndexPage = () => (
  <Layout>
    <hr />
    <div
      style={{
        color: "black",
        paddingLeft: "4rem",
        paddingRight: "4rem",
        textAlign: "center",
        marginTop: "4rem",
      }}
    >
      <div class="heading text-center font-bold text-2xl m-5 text-gray-800">
        Video demo 
      </div>

      <div class="aspect-w-5 aspect-h-2">
        <iframe
          src="https://www.youtube.com/embed/tUdLvZcoXL8"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
